import(/* webpackMode: "eager", webpackExports: ["BrandText"] */ "/vercel/path0/src/app/(index)/BrandText.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GoogleMapEmbed"] */ "/vercel/path0/src/app/(index)/GoogleMapEmbed.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Invitation"] */ "/vercel/path0/src/app/(index)/Invitation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Link"] */ "/vercel/path0/src/components/Link.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Boxes"] */ "/vercel/path0/src/layouts/Boxes.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DefaultPageLayout"] */ "/vercel/path0/src/layouts/DefaultPageLayout.tsx");
