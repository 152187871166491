"use client"

import { FC } from "react"
import { color } from "src/theme/variables"

export const GoogleMapEmbed: FC = () => {
  return (
    <>
      <div>
        <iframe
          title="Karte"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2564.4710172236123!2d12.306359716042602!3d50.00252627941608!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a058ecea455979%3A0x7218da2c09051b58!2sM%C3%BChlbachgasse+8%2C+95652+Waldsassen!5e0!3m2!1sen!2sde!4v1539967293726"
          frameBorder="0"
          allowFullScreen
        />
      </div>

      <style jsx>{`
        div {
          width: 100%;
          height: 20rem;
          background: ${color.googleMapsBackground};
        }

        iframe {
          border: 0;
          width: 100%;
          height: 100%;
        }
      `}</style>
    </>
  )
}
