import { ReactNode } from "react"

export type TimeRestrictedContentProps = {
  start?: Date
  end?: Date
  children: ReactNode
}

export const TimeRestrictedContent = ({
  start,
  end,
  children,
}: TimeRestrictedContentProps) => {
  if (start && new Date() < start) {
    return null
  }

  if (end && new Date() > end) {
    return null
  }

  return children
}
