"use client"

import { Children, FC, ReactNode } from "react"
import { spacing } from "src/theme/variables"

export type BoxesProps = {
  children: ReactNode
}

export const Boxes: FC<BoxesProps> = ({ children }) => (
  <>
    <div className="Boxes">
      {Children.map(children, (child, index) => (
        <div key={index} className="Box">
          {child}
        </div>
      ))}
    </div>

    <style jsx>{`
      .Boxes {
        display: grid;
        align-content: start;
        gap: ${spacing.sectionY} ${spacing.sectionX};
      }

      .Box {
        display: grid;
        justify-items: start;
        align-content: start;
        gap: ${spacing.paragraphY} ${spacing.paragraphX};
      }
    `}</style>
  </>
)
