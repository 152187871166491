"use client"

import { TimeRestrictedContent } from "src/app/(index)/TimeRestrictedContent"

export const Invitation = () => {
  return (
    <TimeRestrictedContent end={new Date("2024-12-25")}>
      <div className="TimeRestrictedContent">
        <img src="/photo_2024-11-29_20-05-56.jpg" alt="Einladung" />
      </div>

      <style jsx>{`
        .TimeRestrictedContent {
          width: 100%;
          max-width: 40rem;
        }

        img {
          width: 100%;
          max-width: 100%;
          border: 1px solid #eee;
          border-radius: 0.5rem;
          overflow: hidden;
        }
      `}</style>
    </TimeRestrictedContent>
  )
}
