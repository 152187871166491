"use client"

import { FC } from "react"

export const BrandText: FC = () => (
  <>
    <div>
      <h1>Christengemeinde</h1>
      <h2>Waldsassen</h2>
    </div>

    <style jsx>{`
      h1,
      h2 {
        margin: 0;
      }
    `}</style>
  </>
)
